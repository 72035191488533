<template>
    <div id="item-ledger-entry-filter">
        <b-row>

            <b-col>
                <v-select size="sm" style="width: 100%;" class="success"
                    :class="searchType == 'MEDICAMENTS' ? 'searchTypeSuccess' : 'searchTypePrimary'"
                    :options="paginated" :filterable="false" @open="onOpen" @close="onClose" label="Description"
                    @search="handleSearch" v-model="selected" ref="select">
                    <template #option="{ description, inventory }">

                        <div>
                            <span class="description" v-html="highlightSearchText(description)"></span>
                            <span class="links" v-if="search.trim().length >= 4" :key="itemKey">
                                <div>
                                    <b-badge variant="secondary" v-if="inventory == undefined">
                                        <b-spinner small type="grow" />
                                    </b-badge>
                                    <b-badge variant="success" v-else-if="inventory">
                                        Stock = {{ inventory > 100 ? '+100' : inventory }}
                                    </b-badge>
                                    <b-badge variant="danger" v-else>
                                        <feather-icon icon="XIcon" />
                                    </b-badge>
                                </div>

                            </span>
                        </div>
                    </template>

                    <span slot="no-options">

                        <span v-if="search.trim() != ''">
                            <div class="d-flex justify-content-center" v-if="allProducts.length == 0">
                                <b-button variant="primary" disabled class="mr-1" size="sm">
                                    <b-spinner small type="grow" />
                                    chargement en cours
                                    <b-spinner small type="grow" />
                                </b-button>
                            </div>

                            <div class="d-flex align-items-center" v-else>
                                Désolé, aucun article trouvé
                            </div>

                        </span>
                        <span v-else>
                            <div class="d-flex justify-content-center" v-if="allProducts.length == 0">
                                <b-button variant="primary" disabled class="mr-1" size="sm">
                                    <b-spinner small type="grow" />
                                    chargement en cours
                                    <b-spinner small type="grow" />
                                </b-button>
                            </div>
                        </span>
                    </span>

                    <template #list-footer>
                        <li v-show="hasNextPage && search.trim() != ''" ref="load" class="loader">
                            Chargement plus d'articles...
                        </li>
                    </template>
                </v-select>


            </b-col>

        </b-row>
    </div>
</template>
<script>

import vSelect from 'vue-select'

export default {
    props: {
        searchType: {
            type: String,
            required: true
        },
    },
    components: {
        vSelect
    },
    data() {
        return {
            itemKey: 0,
            selected: null,
            observer: null,
            limit: 10,
            search: '',
            timeout: null,  // To store the timeout reference
        }
    },
    computed: {
        isNotMobileDevice() {
            return !/Mobi/i.test(navigator.userAgent)
        },
        filtered() {
            if (this.searchType == "TOUS")
                return this.$store.getters["app-items/geItems"].filter(item => item.description.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    .sort((a, b) => {
                        const indexA = a.description.toLowerCase().indexOf(this.search.toLowerCase());
                        const indexB = b.description.toLowerCase().indexOf(this.search.toLowerCase());
                        if (indexA === indexB) {
                            return 0;
                        }
                        return indexA < indexB ? -1 : 1;
                    });
            else if (this.searchType == "MEDICAMENTS")
                return this.$store.getters["app-items/geItemsMED"].filter(item => item.description.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    .sort((a, b) => {
                        const indexA = a.description.toLowerCase().indexOf(this.search.toLowerCase());
                        const indexB = b.description.toLowerCase().indexOf(this.search.toLowerCase());
                        if (indexA === indexB) {
                            return 0;
                        }
                        return indexA < indexB ? -1 : 1;
                    });
            else return this.$store.getters["app-items/geItemsPARA"].filter(item => item.description.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                .sort((a, b) => {
                    const indexA = a.description.toLowerCase().indexOf(this.search.toLowerCase());
                    const indexB = b.description.toLowerCase().indexOf(this.search.toLowerCase());
                    if (indexA === indexB) {
                        return 0;
                    }
                    return indexA < indexB ? -1 : 1;
                });
        },
        allProducts() {
            if (this.searchType == "TOUS")
                return this.$store.getters["app-items/geItems"];
            else if (this.searchType == "MEDICAMENTS")
                return this.$store.getters["app-items/geItemsMED"];
            else
                return this.$store.getters["app-items/geItemsPARA"]
        },
        paginated() {
            if (this.search.trim() == "")
                return [];
            return this.filtered.slice(0, this.limit)
        },
        hasNextPage() {
            return this.paginated.length < this.filtered.length
        },
    },
    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll)
    },
    watch: {
        selected(val) {
            if (val) {
                this.$emit("productClicked", val);
                this.search = "";
                this.selected = null;
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs["select"].$el.querySelector("input").focus();
        });
    },
    methods: {
        async handleSearch(query) {
            this.search = query;
            if (this.timeout) {  // If there is an existing timeout, clear it
                clearTimeout(this.timeout);
            }
            // Set a new timeout to delay fetching inventory data
            this.timeout = setTimeout(async () => {
                if (query.trim().length >= 4) {
                    await this.fetchInventoryData();
                    this.itemKey += 1;  // Increment itemKey to re-trigger any reactive behaviors
                }
            }, 500);  // Delay in milliseconds, adjust as needed
        },
        async fetchInventoryData() {
            try {
                for (const element of this.paginated) {
                    if (!this.inventoryFetched(element)) {
                        const response = await this.$http.get('_item/inventory/' + element.code + '/1');
                        // console.log(response);
                        if (response.data.status == 200) {
                            element.inventory = response.data.result.Quantity_available;
                            element.fetchedTime = new Date();  // Adding fetchedTime attribute when fetching
                        } else {
                            element.inventory = false;
                        }
                        this.$forceUpdate();
                    }
                }
            } catch (error) {
                console.error('Error fetching inventory data:', error);
            }
        },
        inventoryFetched(element) {
            if (element.inventory !== undefined && element.fetchedTime) {
                const currentTime = new Date();
                const fetchedTime = new Date(element.fetchedTime);
                const timeDiff = (currentTime - fetchedTime) / 60000;  // Convert milliseconds to minutes
                return timeDiff < 10;  // Check if fetchedTime is less than 10 minutes
            }
            return false;
        },
        highlightSearchText(text) {
            const regex = new RegExp(this.search, 'gi');
            return text.replace(regex, match => `<b>${match}</b>`);
        },
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick()
                this.observer.observe(this.$refs.load)
            }
        },
        onClose() {
            this.observer.disconnect();
            this.limit = 10;
        },
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent
                const scrollTop = target.offsetParent.scrollTop
                this.limit += this.limit
                await this.$nextTick()
                ul.scrollTop = scrollTop
            }
        },
    },
}

</script>

<style lang="scss">
.loader {
    text-align: center;
    color: #bbbbbb;
}

.description {
    display: inline-block;
    width: 70%;
    /* Ajustez la largeur selon vos besoins */
}

.searchTypeSuccess .vs__dropdown-toggle {
    border-color: #28c76f !important;
}

.searchTypePrimary .vs__dropdown-toggle {
    border-color: #7367f0 !important;
}

.links {
    display: inline-block;
    width: 30%;
    /* Ajustez la largeur selon vos besoins */
    text-align: right;
}
</style>