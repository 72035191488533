<template>
    <div id="basket-details">


        <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

            <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected"
                @itemAddedToCart="loadOrderFromIndexDB" />
            <ProductDetailsMED v-else :product="selected" @sibstitutionItemClick="productClicked"
                @itemAddedToCart="loadOrderFromIndexDB" @sibstitutionItemClickDetail="sibstitutionItemClickDetail" />

        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary">


            <b-card>
                <div>

                    <b-row>
                        <b-col cols="2">
                            <b-dropdown block size="sm" :text="selectedType" split right
                                :variant="selectedType == 'MEDICAMENTS' ? 'outline-success' : 'outline-primary'">
                                <b-dropdown-item @click="typeOnClick('MEDICAMENTS')">
                                    MEDICAMENTS
                                </b-dropdown-item>
                                <b-dropdown-item @click="typeOnClick('PARA-MEDICAMENTS')">
                                    PARA-MEDICAMENTS
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                        <b-col cols="8">
                            <products-select block :searchType="selectedType" @productClicked="productClicked"
                                ref="productsSelect" id="productsSelect"
                                @distributionClick="$refs['distribution-modal'].show();"
                                @arrivalClick="$refs['arrival-modal'].show();"
                                @noveltyClick="$refs['novelty-modal'].show();"
                                @promotionClick="$refs['promotion-modal'].show();"
                                @itemsClick="$refs['items-modal'].show();" :isNotOrderPage="true" />
                        </b-col>

                        <b-col cols="1" class="text-right">
                            <b-button variant="relief-danger" class="w-100" size="sm" @click="deleteBasket">
                                <feather-icon icon="Trash2Icon" />
                            </b-button>
                        </b-col>

                        <b-col cols="1" class="text-right">
                            <b-button variant="relief-success" class="w-100" size="sm" @click="addToCart">
                                <feather-icon icon="ShoppingCartIcon" />
                            </b-button>
                        </b-col>
                    </b-row>


                </div>

                <ag-grid :showHeader="false" :agGrid="agGrid" pageTitle="Liste des articles" ref="agGrid" id="agGrid" />

            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ProductsSelect from '@/views/customer/order/ProductsSelect.vue';
import NumericEditor from '@/views/customer/order/NumericEditor.js'
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BLinkCellRenderer from "@/views/components/ag-grid/BLinkCellRenderer.vue"
import '@core/scss/vue/libs/vue-select.scss';
import ProductDetailsMED from "@/views/product/ProductDetailsMED.vue";
import ProductDetailsPARA from "@/views/product/ProductDetailsPARA.vue";

export default {
    components: {
        AgGrid,
        ProductsSelect,
        ProductDetailsMED,
        ProductDetailsPARA,
    },
    data() {
        return {
            showLoadingModal: false,
            selected: {},
            selectedType: "MEDICAMENTS",
            showLoading: true,
            agGrid: {
                singleClickEdit: true,
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, },
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                    },
                    {
                        headerName: "Type",
                        field: "type",
                        width: 60,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                    },
                    {
                        headerName: "Quantité",
                        field: "Qte",
                        editable: params => params.data.code != "",
                        cellEditor: NumericEditor,
                        width: 100,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { componentName: 'NewOrder', onButtonClick: this.deleteProduct, icon: "backspace", variant: "outline-danger" },
                        headerName: '',
                        width: 40,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                    }
                ],
                rows: []
            },
        }
    },
    watch: {
        $route() {
            this.loadData();
        },
    },
    computed: {
        isNotMobileDevice() {
            return !/Mobi/i.test(navigator.userAgent)
        },
    },
    beforeDestroy() {
        const gridApi = this.$refs.agGrid.gridApi;
        gridApi.removeEventListener("cellKeyPress", this.cellKeyPress);
    },
    async created() {
        if (/Mobi/i.test(navigator.userAgent))
            this.updateColumnsWidth();
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.loadData();
    },
    methods: {
        async addToCart() {
            this.showLoading = true;
            this.agGrid.rows.forEach(element => {
                this.$store.dispatch('app-items/GET_INVENTORY', element);
            });
            setTimeout(() => {
                this.showLoading = false;
                this.$store.dispatch('app-baskets/DELETE_BASKET_HEADER', this.$route.params.slug);
            }, this.agGrid.rows.length * 500);
        },
        deleteBasket() {
            this.$swal({
                title: 'Êtes vous sûr?',
                text: "Vous ne pourrez pas revenir en arrière !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimez-le !',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('app-baskets/DELETE_BASKET_HEADER', this.$route.params.slug);
                    this.$router.push({ name: "new-order" })
                }
            })
        },
        sibstitutionItemClickDetail(data) {
            this.$refs['item-modal'].hide();
            this.selected = data;
            setTimeout(() => {
                this.$refs['item-modal'].show();
            }, 300);
        },
        typeOnClick(value) {
            this.selectedType = value;
            setTimeout(() => {
                this.$refs.productsSelect.$refs["select"].$el.querySelector("input").focus();
            }, 100);
        },
        updateColumnsWidth() {
            const columnIndexToUpdate = this.agGrid.columnDefs.findIndex(column => column.headerName === '#');
            this.agGrid.columnDefs[columnIndexToUpdate].width = 70;
            const columnIndexToUpdate1 = this.agGrid.columnDefs.findIndex(column => column.headerName === '');
            this.agGrid.columnDefs[columnIndexToUpdate1].width = 50;
        },
        async showDetails(data) {
            this.selected = data;
            this.$refs['item-modal'].show();
        },
        async deleteProduct(data) {
            const index = this.agGrid.rows.findIndex(product => product === data);
            if (index !== -1) {
                await this.$store.dispatch('app-baskets/DELETE_BASKET_LINE', data);
                this.agGrid.rows.splice(index, 1);
                this.$refs.agGrid.gridOptions.api.setRowData(this.agGrid.rows);
                if (this.agGrid.rows.length == 1)
                    this.agGrid.rows = [];
            }
        },
        async cellKeyPress(event) {
            if (event.event.key === "Enter") {
                const focusedCell = this.$refs.agGrid.gridOptions.api.getFocusedCell();
                if (focusedCell && focusedCell.column.colId === "Qte") {
                    event.data.basketHeaderId = this.$route.params.slug;
                    await this.$store.dispatch('app-baskets/ADD_BASKET_LINE', event.data);
                    this.$nextTick(() => {
                        this.$refs.productsSelect.$refs["select"].$el.querySelector("input").focus();
                    });
                }
            }
        },
        productClicked(data) {
            this.$refs['item-modal'].hide();
            this.$refs.agGrid.gridOptions.api.setSortModel([]);
            this.$refs.agGrid.gridOptions.api.refreshClientSideRowModel();
            const existingIndex = this.agGrid.rows.findIndex(row => row.code === data.code);
            if (existingIndex !== -1) {
                const existingProduct = this.agGrid.rows.splice(existingIndex, 1)[0];
                existingProduct.Qte = "";
                existingProduct.price = "";
                existingProduct.price_ttc = "";
                existingProduct.public_price = "";
                this.agGrid.rows.unshift(existingProduct);
            } else {
                data.Qte = "";
                data.price = "";
                data.price_ttc = "";
                data.public_price = "";
                this.agGrid.rows.unshift(data);
            }
            setTimeout(() => {
                const rowIndex = 0;
                const colId = "Qte";
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.startEditingCell({ rowIndex, colKey: colId });
            }, 300);
        },
        async loadData() {
            await this.loadOrderFromIndexDB();
            this.$nextTick(() => {
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.addEventListener("cellKeyPress", this.cellKeyPress);
            });
        },
        async loadOrderFromIndexDB() {
            this.showLoading = true;
            const lines = await this.$store.dispatch('app-baskets/GET_BASKET_LINES', this.$route.params.slug);
            this.agGrid.rows = lines;
            this.showLoading = false;
        },
    }
}
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>